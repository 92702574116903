import { createSelector } from '@ngrx/store';
import { selectCalendarFeature } from '../calendar.selectors';
import { planGroupAdapter } from './plan-group.state';

export const selectPlanGroupState = createSelector(
  selectCalendarFeature,
  (s) => s.planGroup
);
const { selectAll } = planGroupAdapter.getSelectors(selectPlanGroupState);
export const selectResponse = createSelector(selectAll, (entities) =>
  entities.sort((a, b) =>
    a?.contributor?.lastName?.localeCompare(b.contributor?.lastName)
  )
);

export const selectPlanGroupDefaultFilter = createSelector(
  selectPlanGroupState,
  (s) => s.defaultFilter
);

export const selectMaxHits = createSelector(
  selectPlanGroupState,
  (s) => s.maxHits
);
