<ng-container *ngIf="formErrors.timeComparison">
  <div
    class="mb-4 text-hrp-accent-700 hrp-text-body-xs-emph flex flex-row items-center justify-between"
  >
    {{ transloco.translate("planning-dialog-error-time") }}
  </div>
</ng-container>

<ng-container *ngIf="formErrors.required">
  <div
    class="mb-4 text-hrp-accent-700 hrp-text-body-xs-emph flex flex-row items-center justify-between"
  >
    {{ transloco.translate("dialog.users.release.text") }}
  </div>
</ng-container>

<ng-container *ngIf="formErrors.isOverlapping">
  <div
    class="mb-4 text-hrp-accent-700 hrp-text-body-xs-emph flex flex-row items-center justify-between"
  >
    Es gibt Überschneidungen mit Ihren bereits eingetragenen Pausen
  </div>
</ng-container>

<ng-container *ngIf="formErrors.possibleBreakTime">
  <div class="mb-4 text-hrp-accent-700 hrp-text-body-xs-emph flex flex-row items-center justify-between">
  {{ transloco.translate("planning-dialog-not-possibble-break") }}
  </div>
</ng-container>
