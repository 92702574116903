import { Injectable } from '@angular/core';
import {
  BreakValues,
  ScheduleItemDto,
  ScheduleItemInfoDto,
} from '@swagger/humanresources';
import { ContributorScheduleService } from '@swagger/humanresources';

@Injectable({ providedIn: 'root' })
export class ContributorScheduleBusinessService {
  constructor(
    private readonly _contributorScheduleService: ContributorScheduleService
  ) {}

  addOrUpdateBreaks({
    employeeUId,
    scheduleUId,
    eagerLoading,
    breakValues,
  }: {
    employeeUId: string;
    scheduleUId: string;
    eagerLoading?: number;
    breakValues: BreakValues[];
  }) {
    return this._contributorScheduleService.contributorScheduleSaveBreaks({
      contributorUId: employeeUId,
      scheduleUId,
      eagerLoading,
      body: breakValues,
    });
  }

  deleteBreaks({
    employeeUId,
    scheduleUId,
    scheduleItemInfos,
  }: {
    employeeUId: string;
    scheduleUId: string;
    scheduleItemInfos: ScheduleItemInfoDto[];
  }) {
    const scheduleItems =
      scheduleItemInfos?.map((item) =>
        this._mapScheduleItemInfoDtoToScheduleItemDto(item)
      ) ?? [];
    return this._contributorScheduleService.contributorScheduleDeleteScheduleItems(
      {
        contributorUId: employeeUId,
        scheduleUId,
        body: scheduleItems,
      }
    );
  }

  private _mapScheduleItemInfoDtoToScheduleItemDto(
    scheduleItemInfo: ScheduleItemInfoDto
  ): ScheduleItemDto {
    return {
      uId: scheduleItemInfo?.uId,
      start: scheduleItemInfo?.start,
      stop: scheduleItemInfo?.stop,
    };
  }
}
