import { NumberInput } from '@angular/cdk/coercion';
import {
  computed,
  Directive,
  effect,
  ElementRef,
  inject,
  Injector,
  input,
  OnInit,
  untracked,
  ViewChild,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { patchState, signalState } from '@ngrx/signals';
import { isElementFullyVisible } from '@shared/utils/html';
import { debounceTime, distinctUntilChanged, fromEvent } from 'rxjs';

type CarouselControlState = {
  containerWidth: number;
  scrollWidth: number;
  scrollLeft: number;
};

@Directive()
export class CarouselControl implements OnInit {
  private readonly injector = inject(Injector);

  readonly state = signalState<CarouselControlState>({
    containerWidth: 0,
    scrollWidth: 0,
    scrollLeft: 0,
  });

  @ViewChild('scrollContainer', { read: ElementRef, static: true })
  scrollContainer: ElementRef;

  get element(): HTMLElement {
    return this.scrollContainer.nativeElement;
  }

  isScrollable = computed(() => {
    return this.state.scrollWidth() > this.state.containerWidth();
  });

  fullyVisibleElements(): Element[] {
    const children = this.element.children;
    const containerRect = this.element.getBoundingClientRect();
    return Array.from(children).filter((child) =>
      isElementFullyVisible(child, containerRect)
    );
  }

  fullyVisibleElementsWidth() {
    const style = window.getComputedStyle(this.element);
    const gap = parseFloat(style.getPropertyValue('gap')) || 0;

    const fullVisibleElements = this.fullyVisibleElements();

    return fullVisibleElements.reduce((acc, el) => {
      return acc + el.getBoundingClientRect().width + gap;
    }, 0);
  }

  ngOnInit(): void {
    const scrollEvent$ = fromEvent(this.element, 'scroll').pipe(
      debounceTime(100),
      distinctUntilChanged()
    );

    const $scrollEvent = toSignal(scrollEvent$, { injector: this.injector });

    effect(
      () => {
        $scrollEvent();
        untracked(() => {
          this.checkAndUpdateState();
        });
      },
      { injector: this.injector }
    );
  }

  private _getContainerWidth(): number {
    return this.element.getClientRects()[0].width;
  }

  private _getScrollWidth(): number {
    return this.element.scrollWidth;
  }

  private _getScrollLeft(): number {
    return this.element.scrollLeft;
  }

  checkAndUpdateState() {
    patchState(this.state, {
      containerWidth: Math.round(this._getContainerWidth()),
      scrollWidth: Math.round(this._getScrollWidth()),
      scrollLeft: Math.round(this._getScrollLeft()),
    });
  }

  left(): void {
    const left = Math.max(
      this.state.scrollLeft() - this.fullyVisibleElementsWidth(),
      0
    );
    this.element.scrollTo({
      left,
      behavior: 'smooth',
    });

    patchState(this.state, { scrollLeft: left });
  }

  right(): void {
    const left = Math.min(
      this.state.scrollLeft() + this.fullyVisibleElementsWidth(),
      this.state.scrollWidth() - this.state.containerWidth()
    );

    this.element.scrollTo({
      left,
      behavior: 'smooth',
    });

    patchState(this.state, { scrollLeft: left });
  }
}
