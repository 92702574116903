import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import {
  matArrowBackIosRound,
  matArrowForwardIosRound,
} from '@ng-icons/material-icons/round';
import { CarouselControl } from './horizontal-scroll-control';

import { CdkObserveContent } from '@angular/cdk/observers';
import { ResizeObserverDirective } from '@shared/resize-observer';

export type HorizontalScrollJustify = '' | 'start' | 'end' | 'center';

@Component({
  selector: 'horizontal-scroll',
  templateUrl: './horizontal-scroll.component.html',
  styleUrls: ['./horizontal-scroll.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIconComponent, CdkObserveContent, ResizeObserverDirective],
  providers: [
    provideIcons({ matArrowBackIosRound, matArrowForwardIosRound }),
    CarouselControl,
  ],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class]': '["horizontal-scroll", justifyClass(), isScrollableClass()]'
  }
})
export class HorizontalScrollComponent extends CarouselControl {

  @ViewChild('scrollContainer', { read: ElementRef, static: true })
  scrollContainer: ElementRef;

  justify = input<HorizontalScrollJustify>('center');

  justifyClass = computed(() => { 
    const justify = this.justify();
    return justify ? `horizontal-scroll-${justify}` : '';
  });

  isScrollableClass = computed(() => { 
    const isScrollable = this.isScrollable()
    return isScrollable ? 'horizontal-scroll-scrollable' : '';
  });
}
