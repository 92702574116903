import {
  AbstractControl,
  AsyncValidatorFn,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import moment from 'moment';
import { firstValueFrom, Observable } from 'rxjs';
import { CalendarEntry } from './types';

export function breakTimeIsOverlappingAsyncValidator(
  isOverlapping$: Observable<boolean>
): AsyncValidatorFn {
  return async (control: AbstractControl): Promise<ValidationErrors | null> => {
    try {
      if (!(control instanceof FormGroup)) {
        return null;
      }
      const overlapError = await firstValueFrom(isOverlapping$);
      return overlapError ? { isOverlapping: true } : null;
    } catch (error) {
      return null;
    }
  };
}

export function breakTimeOverlapWithCalendarEntryValidator(
  startTimeControl: string,
  endTimeControl: string,
  existingBreaks: CalendarEntry[],
  selectedDate: Date,
  currentMode: number | string | null
): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const startTime = group.get(startTimeControl)?.value;
    const endTime = group.get(endTimeControl)?.value;

    if (!startTime || !endTime) {
      return null;
    }

    // Use moment to parse and combine date and time
    const startTimeParsed = moment(selectedDate)
      .set({ hour: parseInt(startTime.split(':')[0]), minute: parseInt(startTime.split(':')[1]) })
      .toDate();

    const endTimeParsed = moment(selectedDate)
      .set({ hour: parseInt(endTime.split(':')[0]), minute: parseInt(endTime.split(':')[1]) })
      .toDate();

    // Filter out the break that is currently being edited
    const filteredBreaks = (typeof currentMode === 'number' && currentMode >= 0 && currentMode < existingBreaks.length)
      ? existingBreaks.filter((_, index) => index !== currentMode)
      : existingBreaks;

    // Check for overlapping breaks
    const hasOverlap = filteredBreaks.some(breakTime => {
      const breakStartTime = moment(breakTime.start).toDate().getTime();
      const breakEndTime = moment(breakTime.end).toDate().getTime();

      return (
        (startTimeParsed.getTime() >= breakStartTime && startTimeParsed.getTime() < breakEndTime) ||
        (endTimeParsed.getTime() > breakStartTime && endTimeParsed.getTime() <= breakEndTime) ||
        (startTimeParsed.getTime() < breakStartTime && endTimeParsed.getTime() > breakEndTime)
      );
    });

    if (hasOverlap) {
      return { isOverlapping: true };
    }

    return null;
  };
}

