<div
  parScrollContainer="both"
  [setOverflow]="false"
  (reachEnd)="triggerDataLoading()"
  [deltaEnd]="24"
  class="calendar-group-month-container"
  id="calendar-group-month-container"
  #scrollContainer
>
  <div class="calendar-group-month-employee-list">
    <div class="month-employee-list-header">
      <div
        [class.invisible]="(responsive.breakpoint$ | async) !== 'desktop'"
        class="flex items-center justify-center h-24 w-24"
      >
        <span class="hrp-text-body-xs text-[#607385]">{{
          "calendar-person" | transloco
        }}</span>
      </div>
    </div>
    <ng-container *ngIf="employeeList?.length > 0; else emptyTemplateLeft">
      <div
        class="month-employee-row"
        *ngFor="let employee of employeeList; trackBy: trackByEmployees"
      >
        <div class="month-employee-data">
          <span class="hrp-text-body-xs-bold">{{ employee?.lastName }} </span>
          <span class="hrp-text-body-xs">{{ employee?.firstName }} </span>
        </div>
      </div>
    </ng-container>
  </div>
  <div
    class="calendar-group-month-content-container"
    id="calendar-group-month-content-container"
    (touchstart)="onTouchStart($event)"
    (touchmove)="onTouchMove($event)"
    (touchend)="onTouchEnd()"
  >
    <div class="calendar-group-month-header">
      @for(week of weeks; let lastWeek = $last; track week.week){
      <div
        class="calendar-group-month-week"
        [ngStyle]="{ 'border-color': lastWeek ? '#FFF' : '#8593A0' }"
      >
        <div
          class="w-auto grid grid-cols-month h-24 justify-center items-center bg-base"
        >
          @for(weekDay of weekDayNames; let i = $index; let l = $last; track
          weekDay){
          <button
            type="button"
            data-what="calendar-day"
            [attr.data-what]="week.dates[i]?.toISOString()"
            (click)="clickOnCalendarDay(week.dates[i])"
            class="calendar-group-month-day mobile:border-0"
            [ngClass]="{ 'border-r-2': !l }"
          >
            <div
              class="flex flex-col justify-center items-center gap-1 w-full"
              [class.calendar-today]="
                dateService.isToday(week.dates[i]) &&
                (responsive.breakpoint$ | async) !== 'mobile'
              "
            >
              <div
                class="desktop:hrp-text-body-xs-bold tablet:hrp-text-body-xs-emph mobile:hrp-text-body-xs-emph"
                [style.color]="
                  dateService.isToday(week.dates[i]) &&
                  (responsive.breakpoint$ | async) !== 'mobile'
                    ? '#DBFFFD'
                    : '#607385'
                "
              >
                @if ((responsive.breakpoint$ | async) === 'desktop') {
                {{ weekDay.replace(".", "") }}
                } @else {
                {{ weekDay.replace(".", "").charAt(0) }}
                }
              </div>
              <div class="flex flex-row items-center justify-center w-full">
                <div
                  class="desktop:hrp-text-body-small-bold tablet:hrp-text-body-small-bold mobile:hrp-text-body-xs mobile:mt-2"
                  [class.calendar-month-mobile-today]="
                    dateService.isToday(week.dates[i]) &&
                    (responsive.breakpoint$ | async) === 'mobile'
                  "
                  [style.color]="
                    dateService.isToday(week.dates[i]) ? '#DBFFFD' : '#061A2D'
                  "
                >
                  @if ((responsive.breakpoint$ | async) === 'mobile') {
                  {{ week.dates[i] | date : "d" }}
                  } @else {
                  {{ week.dates[i] | date : "dd.MM" }}
                  }
                </div>
                <div
                  *ngIf="(responsive.breakpoint$ | async) === 'desktop'"
                  class="desktop:hrp-text-body-small tablet:hrp-text-body-small mobile:hrp-text-body-small"
                  [style.color]="
                    dateService.isToday(week.dates[i]) &&
                    (responsive.breakpoint$ | async) !== 'mobile'
                      ? '#DBFFFD'
                      : '#495969 '
                  "
                >
                  {{ week.dates[i] | date : ".yy" }}
                </div>
              </div>
            </div>
          </button>
          }
        </div>
      </div>
      }
    </div>
    <ng-container *ngIf="employeeList?.length > 0; else emptyTemplate">
      <div
        class="employee-row"
        *ngFor="let employee of employeeList; trackBy: trackByEmployees"
      >
        @for(week of weeks; let lastWeek = $last; track week.week){
        <div
          [attr.data-which]="
            'calendar-week-' +
            week?.week +
            '-data-for-' +
            employee?.firstName +
            '-' +
            employee?.lastName
          "
          [ngStyle]="{ 'border-color': lastWeek ? '#FFF' : '#8593A0' }"
          class="border-r-[1px] grid grid-cols-month"
        >
          @for(weekDay of weekDayNames; let j = $index; let last = $last; track
          weekDay){
          <div
            [attr.data-what]="
              'calendar-day-' +
              week?.dates[j]?.toISOString() +
              '-data-for-' +
              employee?.firstName +
              '-' +
              employee?.lastName
            "
            class="h-24 border-[#F3F4F6] col-span-1 p-1 flex flex-col gap-1"
            [ngClass]="{ 'border-r-2': !last, 'bg-hrp-subtle-100': last }"
          >
            <ng-container *ngIf="filteredDayEntries$ | async; let entries">
              <ng-container
                *ngIf="
                  entries[week.dates[j].toISOString()]
                    | calendarEmployeeFilter : employee?.uId;
                  let employeeEntries
                "
              >
                <ng-container
                  *ngIf="
                    employeeEntries | calendarTimeIntervalFilter;
                    let timeEntries
                  "
                >
                  <calendar-general-entry
                    *ngFor="
                      let entry of timeEntries.slice(0, 3);
                      let i = index;
                      trackBy: trackByEntries
                    "
                    [amount]="timeEntries.length"
                    [entry]="entry"
                    [day]="week.dates[j]"
                    [parentMode]="'group'"
                    [largeWidth]="false"
                  ></calendar-general-entry>

                  <span
                    *ngIf="timeEntries.length > 3"
                    class="hrp-text-body-xs-emph h-2 w-full flex justify-center items-center"
                    >...</span
                  >
                </ng-container>
              </ng-container>
            </ng-container>
          </div>
          }
        </div>
        }
      </div>
    </ng-container>
  </div>
</div>

<ng-template #emptyTemplate>
  <div
    class="flex flex-col w-auto h-[calc(100vh-400px)] bg-hrp-subtle-300 rounded-button px-12 py-12"
    *ngIf="!loading"
  >
    <p class="hrp-text-body-20-emph text-hrp-subtle-800">
      Keine Daten vorhanden!
    </p>
  </div>
</ng-template>

<ng-template #emptyTemplateLeft>
  <div class="flex flex-col w-full h-full bg-hrp-subtle-300"></div>
</ng-template>
