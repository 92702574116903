import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UiFormFieldModule } from '@paragondata/ngx-ui/form-field';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DateAdapter } from '@paragondata/ngx-ui/core';
import { SelectModule } from '@paragondata/ngx-ui/select';
import { ResponsiveService } from '@core/services';
import { PageDatepickerComponent } from '@shared/ui';
import { DateService } from '@shared/utils';
import { CalendarMode, CalendarNavigationMode } from '@shared/plan-defs';

@Component({
  selector: 'calendar-group-navigation',
  templateUrl: 'calendar-group-navigation.component.html',
  styles: [':host { @apply flex flex-col w-full z-20; }'],
  styleUrls: ['../../pages/my-plan-page.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    PageDatepickerComponent,
    UiFormFieldModule,
    SelectModule,
  ],
})
export class CalendarGroupNavigationComponent
  implements OnChanges, AfterViewInit
{
  @Input() mode: CalendarMode = 'week';
  @Input() selectedDate: Date;
  navigationMode: CalendarNavigationMode = 'date';
  @Input() translations: { [key: string]: string } = {
    'date': 'Datum',
  };
  breakpoint$ = this.responsive.breakpoint$;

  formGroup: FormGroup = new FormGroup({
    date: new FormControl('', [Validators.required]),
    calendarWeek: new FormControl(''),
    year: new FormControl('2023'),
  });
  @Output() selectedDateChanged = new EventEmitter<Date>();
  @Input() inputLocked: boolean = false;
  @Output() dateLockedValueChanged = new EventEmitter<Date | Date[]>();
  @Output() dateLocked = new EventEmitter<boolean>();
  @Input() range: Date[] = [];
  focusout: boolean = true;
  invalid: boolean = false;

  constructor(
    private responsive: ResponsiveService,
    private dateAdapter: DateAdapter,
    private el: ElementRef,
    private cdr: ChangeDetectorRef,
    private dateService: DateService
  ) {
    this.formGroup.get('date')?.valueChanges.subscribe((value) => {
      if (this.formGroup.get('date').valid) {
        this.onDateInput(this.formGroup.get('date').value);
      } else {
        this.invalid = true;
        this.dateLocked.emit(false);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDate || changes.mode) {
      this.onDateChange(this.selectedDate);
    }

    if (this.mode === 'month') {
      this.range = this.dateService.getDateRangeForMonth(this.selectedDate);
    } else if (this.mode === 'week') {
      this.range = this.dateService.getDateRangeForWeek(this.selectedDate);
    } else {
      this.range = undefined;
    }
    if (this.range) {
      this.handleDateField(this.range);
    }

    this.cdr.markForCheck();
  }

  private handleDateField(value: Date | string | Date[]) {
    this.formGroup.get('date').setValue(value, { emitEvent: false });
  }

  public onDateInput(value?: Event) {
    const dateControl = this.formGroup.get('date');

    if (dateControl && dateControl.valid) {
      this.dateLocked.emit(true);
      this.dateLockedValueChanged.emit(dateControl.value);
      this.invalid = false;

      if (Array.isArray(dateControl.value)) {
        if (
          this.dateAdapter.compareDate(
            dateControl.value[0],
            this.selectedDate
          ) !== 0
        ) {
          this.selectedDateChanged.emit(dateControl.value[0]);
        }
      } else {
        //Fall abfangen: man macht Leerzeichen (deswegen das if notwendig)
        if (
          this.dateAdapter.compareDate(dateControl.value, this.selectedDate) !==
          0
        ) {
          this.selectedDateChanged.emit(dateControl.value);
        }
      }
    }
  }

  onDateChange(date: Date) {
    if (this.mode === 'day') {
      this.handleDateField(date);
    } else {
      this.handleDateField(this.range);
    }
  }

  ngAfterViewInit() {
    const inputElement: HTMLInputElement =
      this.el.nativeElement.querySelector('input');
    const dateControl = this.formGroup.get('date');

    if (inputElement) {
      inputElement.addEventListener('focus', () => {
        this.focusout = false;
      });

      inputElement.addEventListener('blur', () => {
        this.focusout = true;
        if (dateControl && this.invalid && this.focusout) {
          if (
            this.dateAdapter.compareDate(
              this.selectedDate,
              this.dateAdapter.today()
            ) === 0
          ) {
            this.onDateChange(this.dateAdapter.today());
          } else {
            this.selectedDateChanged.emit(this.dateAdapter.today());
          }
        }
      });
    }
  }

  setNavigationMode(mode: CalendarNavigationMode) {
    this.navigationMode = mode;
  }
}
