import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Filter, IFilter, FilterInput, ParFilterComponent, FilterModule } from '@paragondata/ngx-ui/filter';
import { QuerySettingsDto } from '@swagger/humanresources';
import { PlanGroupStore } from '../../store/plan-group/plan-group.store';
import { firstValueFrom } from 'rxjs';
import { CommonModule } from '@angular/common';
import { FilterCategoryComponent, FilterHeaderComponent } from '@shared/ui';
import { Params } from '@angular/router';

@Component({
  selector: 'calendar-group-filter',
  templateUrl: 'calendar-group-filter.component.html',
  standalone: true,
  imports: [CommonModule, FilterModule, FilterHeaderComponent, FilterCategoryComponent],
  styleUrls: ['calendar-filter.component.scss'],
})
export class CalendarGroupFilterComponent implements OnChanges {
  @Input()
  filterSettings: QuerySettingsDto;
  @Output()
  closeFilterOverlayEvent: EventEmitter<{ active: boolean; reload: boolean }> =
    new EventEmitter();
  @Output() hasSelectedOptions$: EventEmitter<boolean> = new EventEmitter();

  private _filterFromSettings: Filter;

  get filterFromSettings(): Filter {
    return this._filterFromSettings;
  }

  set filterFromSettings(settings: QuerySettingsDto) {
    this._filterFromSettings = Filter.create(settings as IFilter);
  }

  constructor(private store: PlanGroupStore, private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filterSettings) {
      this.filterFromSettings = this.filterSettings;
    }
  }

  closeFilter(active: boolean, reload: boolean) {
    this.closeFilterOverlayEvent.emit({ active, reload });
  }

  applyFilter() {
    const filterGroup = this.createFilter(this._filterFromSettings);
    const apply = this.changeCheck(filterGroup);
    if (apply) {
      this.store.clearData();
      this.store.updateFilter(filterGroup);
      this.store.updateQueryParams(filterGroup.getQueryParams());
    }
    this.closeFilter(apply, true);
  }

  clearFilter(value: Filter) {
    value.unselectAllFilterOptions();
    this.markForCheck();
  }

  async setDefault() {
    const defaultSettings = await firstValueFrom(this.store.defaultFilter$);
    this.filterFromSettings = this.createFilter(defaultSettings);
    this.store.clearData();
    this.store.updateQueryParams(this.filterFromSettings.getQueryParams());
    this.markForCheck();
    this.closeFilter(false, true);
  }

  hasSelectedOptions(filter: Filter) {
    const hasInputOptions = !!filter.input?.find(
      (input) => !!input.input?.find((fi) => fi.hasFilterInputOptionsSelected())
    );

    const hasFilterOptions = !!filter.filter?.find(
      (input) => !!input.input?.find((fi) => fi.hasFilterInputOptionsSelected())
    );

    this.hasSelectedOptions$.emit(hasInputOptions || hasFilterOptions);
    this.markForCheck();
  }

  markForCheck() {
    setTimeout(() => this.cdr.markForCheck(), 0);
  }

  calcContainerHeight(inputs: FilterInput[], activeInput: FilterInput) {
    const categoryCount = inputs?.indexOf(activeInput) + 1;
    // Formel: 100vh - (Abstand Overlay nach oben + Filter Header + Filter Header Padding + (Anzahl Kategorien oberhalb der aktiven * Kategorie Höhe) + gewünschtes Padding nach unten)
    return `calc(100vh - ${(160 + 65 + 16 + categoryCount * 52 + 16) / 16}rem)`;
  }

  changeCheck(newFilter: Filter): boolean {
    //check if new filter and current filter are equal
    const currentFilter = this.createFilter(this.store.$filter());
    const currentFilterQuery = currentFilter.getQueryParams();
    const newFilterQuery = newFilter.getQueryParams();
    return (
      JSON.stringify(currentFilterQuery) !== JSON.stringify(newFilterQuery)
    );
  }

  private createFilter(settings: QuerySettingsDto): Filter {
    return Filter.create(settings as IFilter);
  }
}
