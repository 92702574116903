import { Injectable } from '@angular/core';
import { HrpOrganizationalUnitService } from '@features/administration/business';
import { OrganizationalUnitDto } from '@swagger/humanresources';
import { map, firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CalendarFilterService {
  constructor(public organizationalUnitService: HrpOrganizationalUnitService) {}

  async getFilterFeedbackLabels(
    filteredOU: OrganizationalUnitDto
  ): Promise<OrganizationalUnitDto> {
    if (filteredOU.type === 'PU') {
      return await firstValueFrom(
        this.organizationalUnitService
          .getOrganizationalUnitByUId(filteredOU.parent?.uId)
          .pipe(
            map(async (parentOU) => {
              const parentLabels = await this.getFilterFeedbackLabels(parentOU);
              const modifiedOU = {
                ...filteredOU,
                name:
                  (parentLabels.shortName || parentLabels.name) +
                  ' / ' +
                  filteredOU.name,
              };
              return modifiedOU;
            })
          )
      );
    } else {
      return filteredOU;
    }
  }

  async getFilteredOrganizationalUnitsByStrings(
    ouIds: string[]
  ): Promise<OrganizationalUnitDto[]> {
    const ous = this.getOrganizationalUnitsToDisplay(
      await this.getOUsByUIds(ouIds)
    );

    const filtered = ous.map(async (ou) => {
      const filtered = await this.getFilterFeedbackLabels(ou);
      return filtered;
    });

    return await Promise.all(filtered);
  }

  private async getOUsByUIds(
    ouIds: string[]
  ): Promise<OrganizationalUnitDto[]> {
    try {
      return await firstValueFrom(
        this.organizationalUnitService.getOrganizationUnitsByUIds(ouIds)
      );
    } catch (error) {
      console.error('Error while fetching OUs by UIds', error);
    }
  }

  //dont display children if parent is selected, bc this means that all children are selected so its enough to display the parent name
  private getOrganizationalUnitsToDisplay(
    filteredOUs: OrganizationalUnitDto[]
  ) {
    const parentIdsSet = new Set(filteredOUs.map((ou) => ou.parent?.uId));
    const selectedParents = filteredOUs.filter((ou) =>
      parentIdsSet.has(ou.uId)
    );

    const dontDisplayOUsSet = new Set();
    selectedParents.forEach((ou) => {
      const children = filteredOUs.filter((fou) => fou.parent?.uId === ou.uId);
      children.forEach((child) => dontDisplayOUsSet.add(child));
    });
    return filteredOUs.filter((ou) => !dontDisplayOUsSet.has(ou));
  }
}
