<button
  class="horizontal-scroll__control horizontal-scroll__control__left"
  (click)="left()"
  data-what="horizontal-scroll-left"

>
  <ng-icon
    name="matArrowBackIosRound"
    class="horizontal-scroll__control-icon"
  ></ng-icon>
</button>
<div
  class="horizontal-scroll__content"
  [class]="justifyClass()"
  #scrollContainer
  (cdkObserveContent)="checkAndUpdateState()"
  (resizeObserver)="checkAndUpdateState()"
  data-what="horizontal-scroll-content"
>
  <ng-content></ng-content>
</div>
<button
  class="horizontal-scroll__control horizontal-scroll__control__right"
  (click)="right()"
  data-what="horizontal-scroll-right"
>
  <ng-icon
    name="matArrowForwardIosRound"
    class="horizontal-scroll__control-icon"
  ></ng-icon>
</button>
