export function isElementFullyVisible(
  el: Element,
  hostOrHostClientRect: Element | DOMRect
): Boolean {
  const rect = el.getBoundingClientRect();
  const hostRect =
    hostOrHostClientRect instanceof Element
      ? hostOrHostClientRect.getBoundingClientRect()
      : hostOrHostClientRect;
  return (
    rect.top >= hostRect.top &&
    rect.left >= hostRect.left &&
    rect.bottom <= hostRect.bottom &&
    rect.right <= hostRect.right
  );
}
