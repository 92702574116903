import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';

@Component({
  selector: 'dialog-form-errors',
  templateUrl: './dialog-form.errors.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslocoModule],
})
export class DialogFormErrorsComponent {
  @Input() formErrors: {
    timeComparison: boolean;
    isOverlapping: boolean;
    possibleBreakTime: boolean;
    required: boolean;
  };
  constructor(public transloco: TranslocoService) {}
}
