<span class="hrp-text-body-xs text-hrp-subtle-900">Pausenslot Belegung</span>
<button
  (click)="openDialog.emit()"
  type="button"
  [class.tablet-button-styles]="breakpointValue === 'tablet'"
  [class.desktop-button-styles]="breakpointValue === 'desktop'"
  class="text-white flex items-center justify-center"
>
  <span class="material-symbols-rounded hrp-text-body">edit</span>
</button>
