<div
  *ngIf="allBreakItems"
  class="w-full h-full grid grid-cols-time-intervals border-b-2 border-[#D3D9DF] bg-[#FFF]"
  data-which="break-time-data-row"
>
  @for(time of timeIntervalsGroup; let i = $index; track time){
  <div
    [attr.data-what]="
      'break-time-interval-for-' + time?.hours + ':' + time?.minutes
    "
    *ngIf="time?.minutes === 0 || time?.minutes === 30"
    class="day-cell h-full group"
    #cell
    [ngClass]="{
      'border-[#FFF]': i % 4 === 1 || i % 4 === 3,
      'border-[#F3F4F6]': i % 4 === 0,
      'border-[#D3D9DF]': i % 4 === 2
    }"
  >
    @if(isConributorPresent(time)) {
    <div
      *ngIf="getBreakInfosByTimeInterval(time); let breakInfo"
      class="flex items-center justify-center h-full relative"
    >
      <button
        (click)="addOrRemoveSingleBreak({ time, breakInfo })"
        class="absolute top-[0.125rem] primary xs button-hidden group-hover:flex items-center justify-center hrp-text-body w-6"
      >
        <span
          *ngIf="
            breakInfo.containsBreakForCurrentUser &&
            breakInfo.takenBreaksCount > 0
          "
          class="material-symbols-rounded"
          >remove</span
        >
        <span
          *ngIf="!breakInfo.containsBreakForCurrentUser"
          class="material-symbols-rounded"
          >add</span
        >
      </button>
      <span
        class="flex items-center justify-center text-hrp-subtle-900 hrp-text-body-small-emph"
        [class.self-noted-break]="breakInfo.containsBreakForCurrentUser"
      >
        {{ breakInfo.takenBreaksCount > 0 ? breakInfo.takenBreaksCount : "" }}
      </span>
    </div>
    }
  </div>

  }
</div>
