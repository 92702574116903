import { CommonModule, WeekDay } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { DateAdapter } from '@paragondata/ngx-ui/core';
import { TranslocoModule } from '@jsverse/transloco';
import { CalendarBodyBase } from './calendar-body-base';
import { ParProgressModule } from '@paragondata/ngx-ui/progress';
import { BehaviorSubject } from 'rxjs';
import { ContributorInfoDto } from '@swagger/humanresources';
import { CalendarGeneralEntryComponent, ParScrollContainerDirective } from '@shared/ui';
import { CalendarTimeIntervalFilterPipe, DateService } from '@shared/utils';
import { ResponsiveService } from '@core/services';
import { CalendarEmployeeFilterPipe } from '@features/calendar/business';
import { CalendarEntry, CalendarMode } from '@shared/plan-defs';
import { CalendarEntriesDayFilterService, CalendarEntriesFilterService } from '@features/my-plan';

@Component({
  selector: 'calendar-group-body-week',
  templateUrl: 'calendar-group-body-week.component.html',
  styleUrls: ['calendar-group-body.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CalendarGeneralEntryComponent,
    TranslocoModule,
    CalendarTimeIntervalFilterPipe,
    ParProgressModule,
    ParScrollContainerDirective,
    CalendarEmployeeFilterPipe
  ],
})
export class CalendarGroupBodyWeekComponent
  extends CalendarBodyBase
  implements OnChanges
{
  private destroy = inject(DestroyRef);
  today = this.dateAdapter.today();
  weekDayNames = this.dateAdapter.getWeekdaysShort(WeekDay.Monday);
  weekDates: Date[];

  @Input() date: Date = new Date();
  @Input() entries: CalendarEntry[] = [];
  @Input() employeeList: ContributorInfoDto[] = [];
  @Input() loading: boolean = false;
  @Output() loadMoreSchedules: EventEmitter<void> = new EventEmitter<void>();
  @Output() modeChanged: EventEmitter<CalendarMode> = new EventEmitter<CalendarMode>();
  @Output() dateChanged: EventEmitter<Date> = new EventEmitter
  filteredEntries: CalendarEntry[] = [];
  filteredEntries$ = new BehaviorSubject<CalendarEntry[]>([]);
  filteredDayEntries: { [key: string]: CalendarEntry[] } = {};
  filteredDayEntries$ = new BehaviorSubject<{ [key: string]: CalendarEntry[] }>(
    {}
  );
  employeeList$ = new BehaviorSubject<ContributorInfoDto[]>([]);

  constructor(
    private dateAdapter: DateAdapter,
    private cdr: ChangeDetectorRef,
    public responsive: ResponsiveService,
    public dateService: DateService,
    public entriesFilterService: CalendarEntriesFilterService,
    public entriesDayFilterService: CalendarEntriesDayFilterService
  ) {
    super();
  }
  ngOnChanges({date, entries}: SimpleChanges): void {
    if (date?.currentValue) {
      const lastDayOfCalendarWeek = this.dateAdapter.getLastDateOfWeek(this.date); // Fix - Ticket 2488 Plan - Woche // 30.12 und 31.12 werden nicht angezeigt
      this.weekDates = this.dateAdapter.getDatesForCalendarWeek(
        lastDayOfCalendarWeek.getFullYear(),
        this.dateAdapter.getCalendarWeek(this.date)
      );
    }

    if (entries?.currentValue) {
      const filtEnt = this.entriesFilterService.transform(
        this.entries,
        'week',
        this.date
      );
      this.filteredEntries = [];
      this.filteredEntries = filtEnt;
      this.filteredDayEntries = {};
      for (let i = 0; i < this.weekDayNames.length; i++) {
        const weekDay = this.weekDayNames[i];
        const dayEntries = this.entriesDayFilterService.transform(
          this.filteredEntries,
          this.weekDates[i]
        );
        this.filteredDayEntries[weekDay] = dayEntries;
      }
      this.filteredDayEntries$.next(this.filteredDayEntries);
      this.cdr.markForCheck();
    }
  }

  clickOnCalendarDay(date: Date): void {
    this.modeChanged.emit('day');
    this.dateChanged.emit(date);
  }

  triggerDataLoading(): void {
    this.loadMoreSchedules.emit();
  }
}
