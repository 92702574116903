<div class="flex flex-col h-[6rem] gap-0 items-start w-auto pl-6">

  <ng-container *ngIf="navigationMode === 'date'">
    <div class="w-[18rem]">
      <par-form-field
        [control]="formGroup.get('date')"
        class="bg-white hrp-text-body-bold"
      >
        <page-datepicker
          [formGroup]="formGroup"
          controlName="date"
          [isRange]="mode === 'day' ? false : true"
          datepickerClass="ml-6"
          [ngClass]="{
            'border-hrp-primary-500 rounded-button border-2': inputLocked
          }"
          #input
        ></page-datepicker>
      </par-form-field>
    </div>
  </ng-container>
  <ng-container *ngIf="navigationMode === 'calendarweek'">
    <div class="w-[18rem] flex flex-row gap-2">
      <par-form-field
        [control]="formGroup.get('calendarWeek')"
        class="bg-white w-full hrp-text-body-bold"
      >
        <par-select
          formControlName="calendarWeek"
          [displayNameFn]=""
          [placeholder]="'KW'"
        >
        </par-select>
      </par-form-field>
      <par-form-field [control]="formGroup.get('year')" class="bg-white w-full">
        <par-select
          formControlName="year"
          [displayNameFn]=""
          [placeholder]="'Jahr'"
        >
          <!-- <par-select-option
            *ngFor="let pos of positions$ | async"
            [value]="pos.uid"
          >
            <ng-container single>{{ pos.name }}</ng-container>
          </par-select-option> -->
        </par-select>
      </par-form-field>
    </div>
  </ng-container>
</div>
