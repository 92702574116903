import { CommonModule, WeekDay } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { DateAdapter } from '@paragondata/ngx-ui/core';
import { TranslocoModule } from '@jsverse/transloco';
import { CalendarBodyBase } from './calendar-body-base';
import { ParProgressModule } from '@paragondata/ngx-ui/progress';
import { BehaviorSubject } from 'rxjs';
import { CalendarGeneralEntryComponent, ParScrollContainerDirective } from '@shared/ui';
import { ContributorInfoDto } from '@swagger/humanresources';
import { CalendarTimeIntervalFilterPipe, DateService } from '@shared/utils';
import { ResponsiveService } from '@core/services';
import { CalendarEmployeeFilterPipe } from '@features/calendar/business';
import { CalendarEntry, CalendarMode } from '@shared/plan-defs';
import {
  CalendarEntriesDayFilterService,
  CalendarEntriesFilterService,
} from '@features/my-plan';

@Component({
  selector: 'calendar-group-body-month',
  templateUrl: 'calendar-group-body-month.component.html',
  styleUrls: ['calendar-group-body.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CalendarGeneralEntryComponent,
    TranslocoModule,
    CalendarTimeIntervalFilterPipe,
    ParProgressModule,
    ParScrollContainerDirective,
    CalendarEmployeeFilterPipe,
  ],
})
export class CalendarGroupBodyMonthComponent
  extends CalendarBodyBase
  implements OnChanges
{
  private destroy = inject(DestroyRef);

  @Input() loading: boolean = false;
  @Output() loadMoreSchedules: EventEmitter<void> = new EventEmitter<void>();

  today = this.dateAdapter.today();

  @Input() date: Date = new Date();
  @Input() entries: CalendarEntry[] = [];
  @Input() employeeList: ContributorInfoDto[] = [];
  @Output() modeChanged: EventEmitter<CalendarMode> =
    new EventEmitter<CalendarMode>();
  @Output() dateChanged: EventEmitter<Date> = new EventEmitter();

  @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef;

  filteredEntries: CalendarEntry[] = [];
  filteredEntries$ = new BehaviorSubject<CalendarEntry[]>([]);
  filteredDayEntries: { [key: string]: CalendarEntry[] } = {};
  filteredDayEntries$ = new BehaviorSubject<{
    [key: string]: CalendarEntry[];
  }>({});
  filteredEmployeeEntries: {
    [key: string]: { [key: string]: CalendarEntry[] };
  } = {};
  filteredEmployeeEntries$ = new BehaviorSubject<{
    [key: string]: { [key: string]: CalendarEntry[] };
  }>(undefined);
  employeeList$ = new BehaviorSubject<ContributorInfoDto[]>([]);

  weeks = this.dateAdapter.getDatesAndCalendarWeeksForMonth(this.date);
  weekDates;
  weekDayNames = this.dateAdapter.getWeekdaysShort(WeekDay.Monday);
  startX: number = 0;
  startY: number = 0;
  isScrollingHorizontally = false;

  constructor(
    private dateAdapter: DateAdapter,
    private cdr: ChangeDetectorRef,
    public dateService: DateService,
    public responsive: ResponsiveService,
    public entriesFilterService: CalendarEntriesFilterService,
    public entriesDayFilterService: CalendarEntriesDayFilterService
  ) {
    super();
  }


  ngOnChanges({ date, entries }: SimpleChanges): void {
    if (date?.currentValue) {
      this.weeks = this.dateAdapter.getDatesAndCalendarWeeksForMonth(this.date);
    }

    if (entries?.currentValue) {
      this.filteredEntries = this.entriesFilterService.transform(
        this.entries,
        'month',
        this.date
      );

      for (let week of this.weeks) {
        for (let i = 0; i < this.weekDayNames.length; i++) {
          const dayEntries = this.entriesDayFilterService.transform(
            this.filteredEntries,
            week.dates[i]
          );
          this.filteredDayEntries[week.dates[i].toISOString()] = dayEntries;
        }
      }

      this.filteredDayEntries$.next(this.filteredDayEntries);
      this.cdr.markForCheck();
    }
  }

  onTouchStart(event: TouchEvent) {
    if (this.responsive.$isMobile() || this.responsive.$isTablet()) {
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
      this.isScrollingHorizontally = false; // Reset the scroll lock state
    }
  }

  onTouchMove(event: TouchEvent) {
    if (this.responsive.$isMobile() || this.responsive.$isTablet()) {
      const deltaX = event.touches[0].clientX - this.startX;
      const deltaY = event.touches[0].clientY - this.startY;

      // Calculate the absolute movement to determine intent
      const absDeltaX = Math.abs(deltaX);
      const absDeltaY = Math.abs(deltaY);

      if (absDeltaX > absDeltaY && absDeltaX > 10) {
        // Horizontal swipe detected
        this.isScrollingHorizontally = true;
        this.scrollContainer.nativeElement.style.overflowY = 'hidden'; // Disable vertical scroll
      } else if (!this.isScrollingHorizontally && absDeltaY > 10) {
        // Vertical scroll detected
        this.scrollContainer.nativeElement.style.overflowY = 'scroll'; // Re-enable vertical scroll
      }
    }
  }

  onTouchEnd() {
    if (this.responsive.$isMobile() || this.responsive.$isTablet()) {
      // Reset styles when the touch ends
      this.startX = 0;
      this.startY = 0;
      this.isScrollingHorizontally = false;
      this.scrollContainer.nativeElement.style.overflowY = 'scroll';
    }
  }

  clickOnCalendarDay(date: Date): void {
    this.modeChanged.emit('day');
    this.dateChanged.emit(date);
  }

  triggerDataLoading(): void {
    this.loadMoreSchedules.emit();
  }
}
