import { Time } from '@angular/common';

export const timeIntervalsGroup: Time[] = [
  { hours: 24, minutes: 30 },
  { hours: 24, minutes: 45 },
  { hours: 0, minutes: 0 },
  { hours: 0, minutes: 15 },
  { hours: 0, minutes: 30 },
  { hours: 0, minutes: 45 },
  { hours: 1, minutes: 0 },
  { hours: 1, minutes: 15 },
  { hours: 1, minutes: 30 },
  { hours: 1, minutes: 45 },
  { hours: 2, minutes: 0 },
  { hours: 2, minutes: 15 },
  { hours: 2, minutes: 30 },
  { hours: 2, minutes: 45 },
  { hours: 3, minutes: 0 },
  { hours: 3, minutes: 15 },
  { hours: 3, minutes: 30 },
  { hours: 3, minutes: 45 },
  { hours: 4, minutes: 0 },
  { hours: 4, minutes: 15 },
  { hours: 4, minutes: 30 },
  { hours: 4, minutes: 45 },
  { hours: 5, minutes: 0 },
  { hours: 5, minutes: 15 },
  { hours: 5, minutes: 30 },
  { hours: 5, minutes: 45 },
  { hours: 6, minutes: 0 },
  { hours: 6, minutes: 15 },
  { hours: 6, minutes: 30 },
  { hours: 6, minutes: 45 },
  { hours: 7, minutes: 0 },
  { hours: 7, minutes: 15 },
  { hours: 7, minutes: 30 },
  { hours: 7, minutes: 45 },
  { hours: 8, minutes: 0 },
  { hours: 8, minutes: 15 },
  { hours: 8, minutes: 30 },
  { hours: 8, minutes: 45 },
  { hours: 9, minutes: 0 },
  { hours: 9, minutes: 15 },
  { hours: 9, minutes: 30 },
  { hours: 9, minutes: 45 },
  { hours: 10, minutes: 0 },
  { hours: 10, minutes: 15 },
  { hours: 10, minutes: 30 },
  { hours: 10, minutes: 45 },
  { hours: 11, minutes: 0 },
  { hours: 11, minutes: 15 },
  { hours: 11, minutes: 30 },
  { hours: 11, minutes: 45 },
  { hours: 12, minutes: 0 },
  { hours: 12, minutes: 15 },
  { hours: 12, minutes: 30 },
  { hours: 12, minutes: 45 },
  { hours: 13, minutes: 0 },
  { hours: 13, minutes: 15 },
  { hours: 13, minutes: 30 },
  { hours: 13, minutes: 45 },
  { hours: 14, minutes: 0 },
  { hours: 14, minutes: 15 },
  { hours: 14, minutes: 30 },
  { hours: 14, minutes: 45 },
  { hours: 15, minutes: 0 },
  { hours: 15, minutes: 15 },
  { hours: 15, minutes: 30 },
  { hours: 15, minutes: 45 },
  { hours: 16, minutes: 0 },
  { hours: 16, minutes: 15 },
  { hours: 16, minutes: 30 },
  { hours: 16, minutes: 45 },
  { hours: 17, minutes: 0 },
  { hours: 17, minutes: 15 },
  { hours: 17, minutes: 30 },
  { hours: 17, minutes: 45 },
  { hours: 18, minutes: 0 },
  { hours: 18, minutes: 15 },
  { hours: 18, minutes: 30 },
  { hours: 18, minutes: 45 },
  { hours: 19, minutes: 0 },
  { hours: 19, minutes: 15 },
  { hours: 19, minutes: 30 },
  { hours: 19, minutes: 45 },
  { hours: 20, minutes: 0 },
  { hours: 20, minutes: 15 },
  { hours: 20, minutes: 30 },
  { hours: 20, minutes: 45 },
  { hours: 21, minutes: 0 },
  { hours: 21, minutes: 15 },
  { hours: 21, minutes: 30 },
  { hours: 21, minutes: 45 },
  { hours: 22, minutes: 0 },
  { hours: 22, minutes: 15 },
  { hours: 22, minutes: 30 },
  { hours: 22, minutes: 45 },
  { hours: 23, minutes: 0 },
  { hours: 23, minutes: 15 },
  { hours: 23, minutes: 30 },
  { hours: 23, minutes: 45 },
  { hours: 24, minutes: 0 },
  { hours: 0, minutes: 15 },
];
