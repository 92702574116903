import {
  BreakValues,
  ScheduleItemDto,
  ScheduleItemInfoDto,
} from '@swagger/humanresources';

export function mapScheduleItemDtoToScheduleItemInfoDto(
  scheduleItemDto: ScheduleItemDto
): ScheduleItemInfoDto {
  return {
    approvalProcess: scheduleItemDto?.approvalProcess,
    changed: scheduleItemDto?.changed,
    created: scheduleItemDto?.created,
    priority: scheduleItemDto?.priority,
    scheduleItemType: scheduleItemDto?.scheduleItemType,
    start: scheduleItemDto?.start,
    status: scheduleItemDto?.status,
    stop: scheduleItemDto?.stop,
    timeInterval: scheduleItemDto?.timeInterval,
    uId: scheduleItemDto?.uId,
    version: scheduleItemDto?.version,
  };
}

export function mapScheduleItemInfoDtoToBreakValues({
  newValues,
  breakItemsToUpdate,
}: {
  newValues: Partial<ScheduleItemInfoDto>;
  breakItemsToUpdate?: ScheduleItemInfoDto[];
}): BreakValues[] {
  let breakValues: BreakValues[] = [];
  // Create new Break
  if (breakItemsToUpdate?.length === 0) {
    breakValues.push({
      uId: null,
      start: newValues.start,
      stop: newValues.stop,
      period: true, // period: true => setze timeInterval auf 1
      removeOthers: false, // removeOthers: false => lösche keine anderen Pausen
    });
  } else {
    // Update one or multiple Breaks
    for (const breakItem of breakItemsToUpdate) {
      breakValues.push({
        uId: breakItem?.uId,
        start: newValues.start,
        stop: newValues.stop,
        period: true, // period: true => setze timeInterval auf 1
        removeOthers: false, // removeOthers: false => lösche keine anderen Pausen
      });
    }
  }

  return breakValues;
}
