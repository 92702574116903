import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function breakTimePossibleValidator(
  controlName1: string,
  controlName2: string,
  { start, stop }: { start: string[]; stop: string[] }
): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const control1 = group.get(controlName1);
    const control2 = group.get(controlName2);

    if (!control1 || !control2) {
      return null;
    }

    const time1 = control1.value;
    const time2 = control2.value;

    if (!time1 || !time2) {
      return null;
    }

    if (
      time1 < start[0] ||
      time1 > start[start.length - 1] ||
      time2 < stop[0] ||
      time2 > stop[stop.length - 1]
    ) {
      return { possibleBreakTime: true };
    }

    return null;
  };
}
