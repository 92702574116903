import { createEntityAdapter, EntityState } from '@ngrx/entity';
import {
  ContributorScheduleDto,
  QuerySettingsDto,
} from '@swagger/humanresources';

export interface PlanGroupState extends EntityState<ContributorScheduleDto> {
  defaultFilter: QuerySettingsDto;
  error: Error;
  maxHits: number;
}

export const planGroupAdapter = createEntityAdapter<ContributorScheduleDto>({
  selectId: (employeeSchedule) => employeeSchedule?.contributor?.uId,
  sortComparer: (a, b) =>
    a?.contributor?.lastName?.localeCompare(b.contributor?.lastName),
});

export const planGroupInitialState: PlanGroupState =
  planGroupAdapter.getInitialState({
    defaultFilter: undefined,
    filter: undefined,
    error: undefined,
    maxHits: 0,
  });
