import { CommonModule, WeekDay } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
  signal,
  ChangeDetectionStrategy,
  AfterViewInit,
  OnInit,
} from '@angular/core';
import { DateAdapter } from '@paragondata/ngx-ui/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoModule } from '@jsverse/transloco';
import { CalendarBodyBase } from './calendar-body-base';
import { BehaviorSubject } from 'rxjs';
import { ParProgressModule } from '@paragondata/ngx-ui/progress';
import {
  ContributorInfoDto,
  ContributorScheduleDto,
  ScheduleItemInfoDto,
} from '@swagger/humanresources';
import { ParScrollContainerDirective } from '@shared/ui';
import {
  CalendarGroupBodyDayEntryComponent,
  CalendarGroupFullDayEntryComponent,
} from '../body-entry';
import { CalendarTimeFilterPipe, DateService } from '@shared/utils';

import { timeIntervalsGroup, times } from '../calendar-times';
import { ResponsiveService } from '@core/services';
import {
  BreakTimeDayCategoriesComponent,
  BreakTimeDayDataComponent,
} from '@features/calendar/shared/containers/footer';
import { BreakTimeService } from '@shared/break-time';
import { SessionService } from '@core/auth';
import { CalendarEmployeeFilterPipe } from '@features/calendar/business';
import { CalendarEntry } from '@shared/plan-defs';
import { CalendarEntriesFilterService } from '@features/my-plan';

@Component({
  selector: 'calendar-group-body-day',
  templateUrl: 'calendar-group-body-day.component.html',
  styleUrls: ['calendar-group-body.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    CalendarGroupBodyDayEntryComponent,
    CalendarTimeFilterPipe,
    CalendarGroupFullDayEntryComponent,
    ParProgressModule,
    ParScrollContainerDirective,
    BreakTimeDayDataComponent,
    BreakTimeDayCategoriesComponent,
    CalendarEmployeeFilterPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarGroupBodyDayComponent
  extends CalendarBodyBase
  implements OnChanges, AfterViewInit, OnInit
{
  calendarGroupBreakTimeDayService = inject(BreakTimeService);
  today = this.dateAdapter.today();
  weekDayNames = this.dateAdapter.getWeekdaysShort(WeekDay.Monday);
  weekDates: Date[];
  startX: number = 0;
  startY: number = 0;
  isScrollingHorizontally = false;

  private destroy = inject(DestroyRef);
  @Input() loading: boolean = false;
  @Output() loadMoreSchedules: EventEmitter<void> = new EventEmitter<void>();
  @Input() schedules: ContributorScheduleDto[] = [];
  @Input() date: Date;
  @Input() entries: CalendarEntry[] = [];
  @Input() employeeList: ContributorInfoDto[] = [];
  @Output() updateSchedules: EventEmitter<ContributorScheduleDto[]> =
    new EventEmitter<ContributorScheduleDto[]>();
  @ViewChild('cell', { static: true }) cell: ElementRef;
  @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef;

  fullDayEntry$ = new BehaviorSubject<boolean>(false);
  filteredEntries = signal([]);

  session = inject(SessionService);

  get times() {
    return times;
  }

  get timeIntervals() {
    return timeIntervalsGroup;
  }

  constructor(
    private dateAdapter: DateAdapter,
    private cdr: ChangeDetectorRef,
    public responsive: ResponsiveService,
    public dateService: DateService,
    public entriesFilterService: CalendarEntriesFilterService
  ) {
    super();
  }

  ngOnInit(): void {
    this.calendarGroupBreakTimeDayService.changeSchedules$
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((schedules) => {
        this.updateSchedules.emit(schedules);
      });
  }

  ngOnChanges({ entries, date, employeeList, schedules }: SimpleChanges): void {
    if (schedules?.currentValue && schedules.currentValue.length > 0) {
      this.calendarGroupBreakTimeDayService.schedules$.next([
        ...schedules.currentValue,
      ]);
    }

    if (date?.currentValue) {
      this.calendarGroupBreakTimeDayService.selectedDate$.next(
        date.currentValue
      );
    }

    if (entries?.currentValue) {
      this.filteredEntries.set(
        this.entriesFilterService.transform(this.entries, 'day', this.date)
      );
    }

    this.cdr.markForCheck();
  }

  ngAfterViewInit(): void {
    this.scroll();
  }

  onTouchStart(event: TouchEvent) {
    if (this.responsive.$isMobile() || this.responsive.$isTablet()) {
      this.startX = event.touches[0].clientX;
      this.startY = event.touches[0].clientY;
      this.isScrollingHorizontally = false; // Reset the scroll lock state
    }
  }

  onTouchMove(event: TouchEvent) {
    if (this.responsive.$isMobile() || this.responsive.$isTablet()) {
      const deltaX = event.touches[0].clientX - this.startX;
      const deltaY = event.touches[0].clientY - this.startY;

      // Calculate the absolute movement to determine intent
      const absDeltaX = Math.abs(deltaX);
      const absDeltaY = Math.abs(deltaY);

      if (absDeltaX > absDeltaY && absDeltaX > 10) {
        // Horizontal swipe detected
        this.isScrollingHorizontally = true;
        this.scrollContainer.nativeElement.style.overflowY = 'hidden'; // Disable vertical scroll
      } else if (!this.isScrollingHorizontally && absDeltaY > 10) {
        // Vertical scroll detected
        this.scrollContainer.nativeElement.style.overflowY = 'scroll'; // Re-enable vertical scroll
      }
    }
  }

  onTouchEnd() {
    if (this.responsive.$isMobile() || this.responsive.$isTablet()) {
      // Reset styles when the touch ends
      this.startX = 0;
      this.startY = 0;
      this.isScrollingHorizontally = false;
      this.scrollContainer.nativeElement.style.overflowY = 'scroll';
    }
  }

  scroll() {
    let options: ScrollToOptions = {
      left: 16 * 4 * 8,
      behavior: 'instant',
    };
    if (this.scrollContainer?.nativeElement) {
      this.scrollContainer.nativeElement.scrollTo(options);
    }
  }

  triggerDataLoading(reachEnd: Event): void {
    this.loadMoreSchedules.emit();
    let options: ScrollToOptions = {
      left: 16 * 4 * 8,
      top: this.scrollContainer?.nativeElement?.scrollTop - 50,
      behavior: 'instant',
    };
    this.scrollContainer?.nativeElement.scrollTo(options);
  }

  async onAddOrUpdateBreaks({
    scheduleItemInfos = [],
    newValues,
  }: {
    scheduleItemInfos?: ScheduleItemInfoDto[];
    newValues?: { start: string; stop: string }; // Bei Add oder Update notwendig,
  }) {
    const response =
      await this.calendarGroupBreakTimeDayService.addOrUpdateBreaks({
        scheduleItemInfosToUpdate: scheduleItemInfos,
        newValues,
      });
    await this.calendarGroupBreakTimeDayService.updateGroupPlanViewAfterAddOrUpdateBreaks(
      response
    );
  }

  async onRemove(scheduleItemInfos?: ScheduleItemInfoDto[]) {
    const response = await this.calendarGroupBreakTimeDayService.removeBreaks(
      scheduleItemInfos
    );
    await this.calendarGroupBreakTimeDayService.updateGroupPlanViewAfterRemoveBreaks(
      response
    );
  }
}
