import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  signal,
  WritableSignal,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { DialogRef } from '@paragondata/ngx-ui/modal';
import { ScheduleItemInfoDto } from '@swagger/humanresources';
import { combineLatest, debounceTime, map, Observable } from 'rxjs';
import { UiFormFieldModule } from '@paragondata/ngx-ui/form-field';
import { DividerCircleComponent } from '@shared/ui';
import {
  getTime,
  mapStringToTime,
  timeComparisonValidator,
  timeEqualsValidator,
  timeToMinutes,
} from '@shared/utils';
import { DialogFormErrorsComponent } from './dialog-form.errors';
import { DialogFormNotificationComponent } from './dialog-form.notification';
import moment from 'moment';
import { breakTimePossibleValidator } from '../break-time-possible.validator';
import { breakTimeIsOverlappingAsyncValidator } from '../break-time-overlap.async-validator';

export type BreakTimesDialogData = {
  actions: Action[];
  userBreakItems$: Observable<ScheduleItemInfoDto[]>;
  allBreakItems$: Observable<ScheduleItemInfoDto[]>;
  timeOptions$: { start: string[]; stop: string[] };
};
export type Action = {
  type: ActionType;
  label?: string;
  displayIcon?: string;
  callbackFn?: ({
    start,
    stop,
    scheduleItemInfo,
  }: {
    start?: string;
    stop?: string;
    scheduleItemInfo?: ScheduleItemInfoDto;
  }) => any;
};
type ActionType = 'add' | 'edit' | 'remove';

@Component({
  selector: 'dialog-break-times',
  templateUrl: './break-times-dialog.component.html',
  styleUrl: './break-times-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    DividerCircleComponent,
    ReactiveFormsModule,
    UiFormFieldModule,
    DialogFormErrorsComponent,
    DialogFormNotificationComponent,
  ],
})
export class BreakTimesDialogComponent {
  form = new FormGroup({
    breakTimeStart: new FormControl('', [Validators.required]),
    breakTimeStop: new FormControl('', [Validators.required]),
  });

  get possibleStartTimes() {
    return this.dialogRef?.data?.timeOptions$?.start;
  }

  get possibleStopTimes() {
    return this.dialogRef?.data?.timeOptions$?.stop;
  }

  get title() {
    return this.dialogRef?.data?.title;
  }

  get userBreakItems$(): Observable<ScheduleItemInfoDto[]> {
    return this.dialogRef?.data?.userBreakItems$.pipe(
      map((items) =>
        items.sort(
          (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
        )
      )
    );
  }

  get allBreakItems$(): Observable<ScheduleItemInfoDto[]> {
    return this.dialogRef?.data?.allBreakItems$;
  }

  allOverlappingBreakItems$: Observable<ScheduleItemInfoDto[]> = combineLatest([
    this.allBreakItems$,
    this.form.valueChanges,
  ]).pipe(
    map(([allBreakItems, formValue]) => {
      // Wenn es Überschneidungen gibt, gebe die Anzahl der Überschneidungen zurück
      return allBreakItems.filter((item) => {
        const startBreakTime = mapStringToTime(formValue?.breakTimeStart);
        const stopBreakTime = mapStringToTime(formValue?.breakTimeStop);
        const itemStart = getTime(new Date(item?.start));
        const itemStop = getTime(new Date(item?.stop));

        if (
          Number.isNaN(startBreakTime.hours) ||
          Number.isNaN(startBreakTime.minutes) ||
          Number.isNaN(stopBreakTime.hours) ||
          Number.isNaN(stopBreakTime.minutes) ||
          this.form?.invalid // Bevor die Notification displayed wird, sollen zuvor (falls vorhanden) die Errors angezeigt werden
        ) {
          return false;
        }

        // Convert times to minutes for comparison
        const startBreakInMinutes = timeToMinutes(startBreakTime);
        const stopBreakInMinutes = timeToMinutes(stopBreakTime);
        const itemStartInMinutes = timeToMinutes(itemStart);
        const itemStopInMinutes = timeToMinutes(itemStop);

        // Return all items which are between startBreakTime and stopBreakTime
        return (
          itemStartInMinutes < stopBreakInMinutes &&
          itemStopInMinutes > startBreakInMinutes
        );
      });
    })
  );

  hasOverlappingBreakTimesByCurrentUser$ = combineLatest([
    this.allOverlappingBreakItems$,
    this.userBreakItems$,
  ]).pipe(
    map(([overlappingItems, userBreakItems]) => {
      let filteredBreakItems = overlappingItems.filter((item) =>
        userBreakItems?.some((userItem) => userItem?.uId === item?.uId)
      );

      // Check if Edit mode - If yes, filter out the current item
      // Otherwise the user would not be able to edit the current item because it would always overlap with itself
      const isEditMode = this.getDisplayForm() >= 0;
      if (isEditMode) {
        filteredBreakItems = filteredBreakItems.filter(
          (item) => item?.uId !== userBreakItems[this.getDisplayForm()]?.uId
        );
      }

      return filteredBreakItems?.length > 0;
    })
  );

  allOverlappingBreakItemsWithoutCurrentUser$ = combineLatest([
    this.allOverlappingBreakItems$,
    this.userBreakItems$,
  ]).pipe(
    map(([overlappingItems, userBreakItems]) => {
      const filteredBreakItems = overlappingItems.filter(
        (item) =>
          !userBreakItems?.some((userItem) => userItem?.uId === item?.uId)
      );
      return filteredBreakItems?.length;
    })
  );

  get actions(): Action[] {
    return this.dialogRef?.data?.actions;
  }

  get addAction() {
    return this.actions.find((action) => action.type === 'add');
  }

  get editAction() {
    return this.actions.find((action) => action.type === 'edit');
  }

  get removeAction() {
    return this.actions.find((action) => action.type === 'remove');
  }

  $displayForm: WritableSignal<number | null> = signal(null);

  formErrors$: Observable<{
    timeComparison: boolean;
    isOverlapping: boolean;
    possibleBreakTime: boolean;
    required: boolean;
  }> = this.form.valueChanges.pipe(
    debounceTime(1), // DebounceTime ist wichtig, da der overlapValidator Async ist und sonst ggf. noch keinen Wert zurückgegeben hat
    map((_) => {
      return {
        timeComparison: this.timeComparisonFormError && this.form.dirty,
        isOverlapping: this.isOverlappingFormError && this.form.dirty,
        possibleBreakTime: this.possibleBreakTimesError && this.form.dirty,
        required:
          (this.breakTimeStart.invalid || this.breakTimeStop.invalid) &&
          this.breakTimeStart.touched &&
          this.breakTimeStop.touched,
      };
    })
  );

  get timeComparisonFormError() {
    return this.form?.errors?.timeComparison || this.form?.errors?.timeEquals;
  }

  get isOverlappingFormError() {
    return this.form?.errors?.isOverlapping;
  }

  get possibleBreakTimesError() {
    return this.form?.errors?.possibleBreakTime;
  }
  get breakTimeStart() {
    return this.form.get('breakTimeStart');
  }

  get breakTimeStop() {
    return this.form.get('breakTimeStop');
  }

  constructor(
    public dialogRef: DialogRef<
      any,
      {
        title: string;
        actions: Action[];
        userBreakItems$: Observable<ScheduleItemInfoDto[]>;
        allBreakItems$: Observable<ScheduleItemInfoDto[]>;
        timeOptions$: { start: string[]; stop: string[] };
      }
    >,
    public transloco: TranslocoService,
  ) {
    this.form.addValidators([
      timeComparisonValidator('breakTimeStart', 'breakTimeStop'),
      timeEqualsValidator('breakTimeStart', 'breakTimeStop'),
      breakTimePossibleValidator('breakTimeStart', 'breakTimeStop', {
        start: this.possibleStartTimes,
        stop: this.possibleStopTimes,
      }),
    ]);
    this.form.setAsyncValidators(
      breakTimeIsOverlappingAsyncValidator(this.hasOverlappingBreakTimesByCurrentUser$)
    );
  }

  getDisplayForm(): number {
    return this.$displayForm();
  }

  setDisplayForm(index: number) {
    this.$displayForm.set(index);
    this.form.updateValueAndValidity();
  }

  onActionClick({
    type,
    scheduleItemInfo,
    index,
  }: {
    type: ActionType;
    scheduleItemInfo?: ScheduleItemInfoDto;
    index?: number;
  }) {
    switch (type) {
      case 'add':
        this.clearAndCloseForm();
        this.setDisplayForm(-1);
        break;
      case 'edit':
        this.clearAndCloseForm();
        this.setDisplayForm(index);
        // Prepopulate Form with current values
        this.breakTimeStart.setValue(
          this.getTimeShortString(scheduleItemInfo.start)
        );
        this.breakTimeStop.setValue(
          this.getTimeShortString(scheduleItemInfo.stop)
        );
        break;
      case 'remove':
        this.clearAndCloseForm();
        this.removeAction.callbackFn({ scheduleItemInfo });
        break;
    }
  }

  onClose() {
    this.dialogRef.close(this.form);
  }

  checkFormErrors() {
    return (
      this.timeComparisonFormError ||
      this.isOverlappingFormError ||
      this.possibleBreakTimesError
    );
  }

  onSubmit(breakItems: ScheduleItemInfoDto[]) {
    this.form.markAllAsTouched();
    if (this.checkFormErrors() || this.form.invalid) {
      return;
    }
    // Add Action
    if (breakItems?.length === 0 || this.getDisplayForm() === -1) {
      this.addAction.callbackFn({
        start: this.breakTimeStart.value,
        stop: this.breakTimeStop.value,
      });
    } else {
      // Update Action
      const itemToUpdate = breakItems[this.getDisplayForm()];
      this.editAction.callbackFn({
        start: this.breakTimeStart.value,
        stop: this.breakTimeStop.value,
        scheduleItemInfo: itemToUpdate,
      });
    }

    // Close Form
    this.clearAndCloseForm();

    // Only if it is the very first item which gets added => close dialog
    if (breakItems?.length === 0) {
      this.onClose();
    }
  }

  clearAndCloseForm() {
    this.form.reset();
    this.setDisplayForm(null);
  }

  getTimeShortString(dateStringISO: string): string {
    return moment(dateStringISO).format('HH:mm');
  }
}
