import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  ViewEncapsulation,
} from '@angular/core';

export type ChipSize = 'small' | 'medium';

@Component({
  selector: 'chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [],
  encapsulation: ViewEncapsulation.None,
  host: {
    '[class]': '["chip", sizeClass()]',
  }
})
export class ChipComponent {

  size = input<ChipSize>('medium');

  sizeClass = computed(() => {
    const size = this.size();

    return `chip__${size}`;
  });
}
