import { CommonModule, Time } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  inject,
} from '@angular/core';
import { DateAdapter } from '@paragondata/ngx-ui/core';
import { ScheduleItemInfoDto } from '@swagger/humanresources';
import { SkeletonLoaderDirective } from 'src/shared/skeleton-loader/skeleton-loader.directive';
import {
  BreakTimeService,
  filterBreakItemsWithinTime,
  findBreakItemWhichLiesWithinTimespan,
  getISODateByTimeInterval,
} from '@shared/break-time';
import moment from 'moment';

type BreakInfo = {
  takenBreaksCount: number;
  containsBreakForCurrentUser?: boolean;
};

@Component({
  selector: 'page-break-time-day-data',
  templateUrl: 'break-time-day-data.component.html',
  styleUrls: ['break-time-day-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SkeletonLoaderDirective],
})
export class BreakTimeDayDataComponent {
  @Input() allBreakItems: ScheduleItemInfoDto[];
  @Input() allBreakItemsForCurrentUser: ScheduleItemInfoDto[];
  @Input() selectedDate: Date;
  @Input() possibleBreaks: number[];
  @Output() addOrUpdateBreak = new EventEmitter<{
    scheduleItemInfos?: ScheduleItemInfoDto[];
    newValues?: { start: string; stop: string };
  }>();
  @Output() removeBreak = new EventEmitter<ScheduleItemInfoDto[]>();

  calendarGroupBreakTimeDayService = inject(BreakTimeService);
  dateAdapter = inject(DateAdapter);

  get timeIntervalsGroup() {
    return this.calendarGroupBreakTimeDayService.timeIntervalsGroup;
  }

  getBreakInfosByTimeInterval(time: Time): BreakInfo {
    // 1. Filtern aller Pausen für den Tag nach Zeitspanne die über die Funktion reinkommt
    const allBreakItemsForTimespan = filterBreakItemsWithinTime({
      breakItems: this.allBreakItems,
      time,
    });

    // 2. Wenn in der gegebenen Zeitspanne keine Pause vorhanden ist, wird dieses default objekt returned
    if (allBreakItemsForTimespan?.length === 0) {
      return { takenBreaksCount: 0, containsBreakForCurrentUser: false };
    }

    // 3. Informationen für die gegebene Zeitspanne zurückgeben (z.B. 10 Uhr - 10:30 Uhr)
    return {
      takenBreaksCount: allBreakItemsForTimespan?.length ?? 0,
      containsBreakForCurrentUser: this._containsBreakForCurrentUser(
        allBreakItemsForTimespan
      ),
    };
  }

  isConributorPresent(time: Time): boolean {
    return this.possibleBreaks?.includes(this._timeInUnixTimeStap(time));
  }

  addOrRemoveSingleBreak({
    time,
    breakInfo,
  }: {
    time: Time;
    breakInfo?: BreakInfo;
  }) {
    const start = getISODateByTimeInterval({
      selectedDate: this.selectedDate,
      time,
    });
    const stop = getISODateByTimeInterval({
      selectedDate: this.selectedDate,
      time: { hours: time.hours, minutes: time.minutes + 30 },
    });

    if (!start || !stop) {
      return;
    }

    // Prüfen ob eine Pause für den aktuellen User in der gegebenen Zeitspanne existiert
    const breakItemForCurrentUser = findBreakItemWhichLiesWithinTimespan({
      breakItems: this.allBreakItemsForCurrentUser,
      start,
      stop,
    });

    // Wenn eine Pause für den aktuellen User existiert, kann diese nur gelöscht werden für das gewählten Zeitintervall
    const type = breakInfo?.containsBreakForCurrentUser ? 'remove' : 'add';

    // Hier muss überprüft werden, ob die Pause die gelöscht werden soll einen größeren Zeitraum hat als das gewählte Zeitintervall
    // Wenn ja, muss die Pause gesplitted werden -> Eine Hälfte wird geupdated und die andere Hälfte wird neu erstellt
    // -> Sonderfall wenn entweder Anfang oder Ende der Pause removed wird -> Dann wird nur geupdated
    if (
      type === 'remove' &&
      (new Date(breakItemForCurrentUser.start) < new Date(start) ||
        new Date(breakItemForCurrentUser.stop) > new Date(stop))
    ) {
      const startBegin = moment(start).valueOf();
      const startEnd = moment(start).add(30, 'm').valueOf();
      const currentUserStart = moment(breakItemForCurrentUser.start).valueOf();
      const isStartDateInRange =
        currentUserStart >= startBegin && currentUserStart < startEnd;

      const currentUserStop = moment(breakItemForCurrentUser.stop).valueOf();
      const stopBegin = moment(stop).subtract(30, 'm').valueOf();
      const stopEnd = moment(stop).valueOf();
      const isStopDateInRange =
        currentUserStop > stopBegin && currentUserStop <= stopEnd;

      // Wenn Anfang oder Ende removed wird -> Update so, dass entweder der Anfang oder das Ende removed wird
      if (isStartDateInRange || isStopDateInRange) {
        this.addOrUpdateBreak.emit({
          scheduleItemInfos: [breakItemForCurrentUser],
          newValues: isStartDateInRange
            ? { start: stop, stop: breakItemForCurrentUser.stop }
            : { start: breakItemForCurrentUser.start, stop: start },
        });
      } else {
        // Splitte die Pause - Update die eine Hälfte und füge die andere Hälfte als Neue Pause hinzu
        this.addOrUpdateBreak.emit({
          scheduleItemInfos: [breakItemForCurrentUser],
          newValues: { start: breakItemForCurrentUser.start, stop: start },
        });
        this.addOrUpdateBreak.emit({
          newValues: { start: stop, stop: breakItemForCurrentUser.stop },
        });
      }
    } else {
      // Add or Remove Single Break
      if (type === 'add') {
        this.addOrUpdateBreak.emit({
          newValues: { start, stop },
        });
      } else {
        this.removeBreak.emit([breakItemForCurrentUser]);
      }
    }
  }

  // Prüfen ob Pausen für den aktuellen User vorhanden sind um in der UI entsprechende Stylings zu setzen
  private _containsBreakForCurrentUser(
    breaksToCheck: ScheduleItemInfoDto[]
  ): boolean {
    return (
      !!this.allBreakItemsForCurrentUser?.find((item) =>
        breaksToCheck.find((breakItem) => breakItem.uId === item.uId)
      ) ?? false
    );
  }

  private _timeInUnixTimeStap(time: Time) {
    return new Date(this.selectedDate).setHours(time.hours, time.minutes);
  }
}
