import { Time } from '@angular/common';
import { getTime, greaterEqualTime, lessTime } from '@shared/utils';
import { ScheduleItemDto, ScheduleItemInfoDto } from '@swagger/humanresources';

export function filterBreakItemByTypeAndIntervalAndDay(
  scheduleItemInfo: ScheduleItemInfoDto,
  day: Date
) {
  return (
    (scheduleItemInfo?.scheduleItemType?.key === 'BREAK' ||
      (scheduleItemInfo as ScheduleItemDto)?.scheduleItemType?.data?.key ===
        'BREAK') && // Fallback Notwendig da bei Hinzufügen oder Updaten von Pausen ein ScheduleItemDto zurückkommt
    scheduleItemInfo?.timeInterval === 1 &&
    day.toLocaleDateString() ===
      new Date(scheduleItemInfo.start).toLocaleDateString()
  );
}

export function filterAllBreakItems(scheduleItemInfo: ScheduleItemInfoDto) {
  return (
    scheduleItemInfo?.scheduleItemType?.key === 'BREAK' &&
    (scheduleItemInfo?.timeInterval === 1 ||
      scheduleItemInfo?.timeInterval === 2 ||
      scheduleItemInfo?.scheduleItemType?.timeInterval === 1 ||
      scheduleItemInfo?.scheduleItemType?.timeInterval === 2)
  );
}

export function filterAllBreakItemsDuration(
  scheduleItemInfo: ScheduleItemInfoDto
) {
  return (
    scheduleItemInfo?.scheduleItemType?.key === 'BREAK' &&
    scheduleItemInfo?.timeInterval === 2
  );
  //|| scheduleItemInfo?.scheduleItemType?.timeInterval === 2, müsste mit rein, wird aber aktuell im backend falsch gesetzt was hier zu einem falschen Ergebnis führt
}

export function filterAllBreakItemsTimespan(
  scheduleItemInfo: ScheduleItemInfoDto
) {
  return (
    scheduleItemInfo?.scheduleItemType?.key === 'BREAK' &&
    (scheduleItemInfo?.timeInterval === 1 ||
      scheduleItemInfo?.scheduleItemType?.timeInterval === 1)
  );
}

export function findBreakItemWhichLiesWithinTimespan({
  breakItems,
  start,
  stop,
}: {
  breakItems: ScheduleItemInfoDto[];
  start: string;
  stop: string;
}) {
  return breakItems.find((item) => {
    const itemStart = new Date(item?.start);
    const itemStop = new Date(item?.stop);
    if (
      !(itemStart.getTime() > new Date(stop).getTime()) &&
      itemStop.getTime() > new Date(start).getTime()
    ) {
      return item;
    }
  });
}

export function getBreakItemWithTimeInterval2(
  scheduleItemInfoDtos: ScheduleItemInfoDto[]
): ScheduleItemInfoDto {
  return scheduleItemInfoDtos.find((item) => {
    if (item.scheduleItemType?.key === 'BREAK') {
      return (
        item.timeInterval === 2 && item.scheduleItemType?.timeInterval === 2
      );
    }
  });
}

export function filterBreakItemsWithinTime({
  breakItems,
  time,
}: {
  breakItems: ScheduleItemInfoDto[];
  time: Time;
}): ScheduleItemInfoDto[] {
  return breakItems?.filter((item) => {
    const start = getTime(new Date(item.start));
    const stop = getTime(new Date(item.stop));

    if (!checkForMinimumIntervalTime({ start, stop, fraction: 5 })) {
      return false;
    }

    // #2179 Änderung der Implementierung, sodass auch Intervalle zwischen den Halben Stunden berücksichtigt werden
    // -> z.B. Pause 14:00 - 14:30 würde bei Time 14:00 angezeigt werden
    // NEU: -> z.B. Pause 14:10 - 15:45 würde bei Time 14:00, 14:30, 15:00 und 15:30 angezeigt werden
    const intervalStart = time;
    const intervalEnd = addMinutesToTime(time, 30);
    return (
      lessTime(start, intervalEnd) &&
      greaterEqualTime(stop, intervalStart) &&
      !equalTime(stop, intervalStart)
    );
  });
}

export function filterBreakItemsByTimespan({
  breakItems,
  start,
  stop,
}: {
  breakItems: ScheduleItemInfoDto[];
  start: Time;
  stop: Time;
}): ScheduleItemInfoDto[] {
  return breakItems?.filter((breakItem) => {
    const startItem = getTime(new Date(breakItem.start));
    const stopItem = getTime(new Date(breakItem.stop));
    return !(greaterEqualTime(start, startItem) || lessTime(stop, stopItem));
  });
}

export function getISODateByTimeInterval({
  selectedDate,
  time,
}: {
  selectedDate: Date;
  time: Time;
}): string {
  const date = new Date(selectedDate); // from selectedDate to get correct Day, Month and Year
  date?.setHours(time.hours, time.minutes, 0, 0);
  return date?.toISOString();
}

function addMinutesToTime(time: Time, minutes: number): Time {
  let newHours = time.hours;
  let newMinutes = time.minutes + minutes;

  if (newMinutes >= 60) {
    newHours += Math.floor(newMinutes / 60);
    newMinutes %= 60;
  }

  return { hours: newHours, minutes: newMinutes };
}

function equalTime(first: Time, second: Time): boolean {
  return first?.hours === second?.hours && first?.minutes === second?.minutes;
}

function checkForMinimumIntervalTime({
  start,
  stop,
  fraction,
}: {
  start: Time;
  stop: Time;
  fraction: number;
}): boolean {
  const startInMinutes = start.hours * 60 + start.minutes;
  const stopInMinutes = stop.hours * 60 + stop.minutes;
  return stopInMinutes - startInMinutes >= 30 / fraction;
}
