import {
  Directive,
  effect,
  ElementRef,
  EventEmitter,
  inject,
  Injector,
  input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { debounce } from 'lodash';

/**
 * Eine Direktive, die einen ResizeObserver verwendet, um Änderungen der Größe eines Elements zu überwachen.
 *
 * @export
 * @class ResizeObserverDirective
 * @implements {OnInit}
 * @implements {OnDestroy}
 *
 * @example
 * ```html
 * <div resizeObserver (resizeObserver)="onResize($event)"></div>
 * ```
 *
 * @description
 * Diese Direktive verwendet den ResizeObserver, um Änderungen der Größe eines Elements zu überwachen und ein Event auszulösen, wenn eine Größenänderung erkannt wird.
 *
 * @property {EventEmitter<ResizeObserverEntry>} resize - Ein EventEmitter, der ein ResizeObserverEntry-Objekt ausgibt, wenn eine Größenänderung erkannt wird.
 *
 * @method ngOnInit
 * Initialisiert den ResizeObserver und beginnt mit der Überwachung des Elements.
 *
 * @method ngOnDestroy
 * Beendet die Überwachung des Elements und trennt den ResizeObserver.
 */
@Directive({ selector: '[resizeObserver]', standalone: true })
export class ResizeObserverDirective implements OnInit, OnDestroy {
  private _elementRef = inject(ElementRef);

  private _injector = inject(Injector);

  private resizeObserver: ResizeObserver;

  debounceTime = input<number>(0);

  @Output('resizeObserver') resize = new EventEmitter<ResizeObserverEntry[]>();

  constructor() {}

  ngOnInit(): void {
    effect(
      () => {
        this._createObserver(this.debounceTime());
      },
      { injector: this._injector }
    );
  }

  private _createObserver(debounceTime: number): void {
    debounceTime = Math.max(0, debounceTime);

    let emitResize = (entries: ResizeObserverEntry[]) => {
      this.resize.emit(entries);
    };

    if (debounceTime > 0) {
      emitResize = debounce(emitResize, debounceTime);
    }

    this.resizeObserver?.disconnect();
    this.resizeObserver = new ResizeObserver(emitResize);

    this.resizeObserver.observe(this._elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
  }
}
