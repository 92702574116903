<ng-container *ngIf="filterFromSettings.filter | group : 'filter'">
  <par-filter
    class="grid grid-flow-row mobile:h-[24rem] mobile:max-h-[24rem] max-w-[34rem] w-[34rem] mobile:w-full mobile:max-w-full mobile:bg-hrp-base mobile:rounded mobile:shadow-card"
    *ngIf="filterFromSettings; let filter"
    [filter]="filter"
  >
    <ng-container
      custom-filter-layout
      *ngIf="filter?.filter | group : 'filter'; let filterInputGroup"
    >
      <filter-header
        (applyFilter)="applyFilter()"
        (resetFilter)="setDefault()"
      ></filter-header>
      <filter-category
        #category
        [filterInputGroup]="filterInputGroup"
      ></filter-category>
    </ng-container>
  </par-filter>
</ng-container>
