import { inject, Injectable, signal, WritableSignal } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectResponse,
  selectPlanGroupState,
  selectMaxHits,
  selectPlanGroupDefaultFilter,
} from './plan-group.selectors';
import { planGroupActions, planGroupFilterActions } from './plan-group.actions';
import {
  ContributorScheduleDto,
  QuerySettingsDto,
} from '@swagger/humanresources';
import { map } from 'rxjs';
import { CalendarMode } from '@shared/plan-defs';
import { Params } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class PlanGroupStore {
  private store = inject(Store);
  response$ = this.store.select(selectResponse);
  error$ = this.store.select(selectPlanGroupState).pipe(map((s) => s.error));
  defaultFilter$ = this.store.select(selectPlanGroupDefaultFilter);
  $filter: WritableSignal<QuerySettingsDto | null> = signal(null);
  maxHits$ = this.store.select(selectMaxHits);
  $queryParams: WritableSignal<Params> = signal({});

  query(date: Date, mode: CalendarMode, skip: number = 0) {
    this.store.dispatch(
      planGroupActions.request({ date, mode, filter: this.$filter(), skip })
    );
  }

  async queryFilter() {
    this.store.dispatch(planGroupFilterActions.request());
  }

  updateFilter(filter: QuerySettingsDto) {
    this.$filter.set(filter);
  }

  setEntities(entities: ContributorScheduleDto[]) {
    this.store.dispatch(planGroupActions.setEntities({ entities }));
  }

  clearData() {
    this.store.dispatch(planGroupActions.clear());
  }

  getActiveScheduleIdByEmployeeUId(
    schedules: ContributorScheduleDto[],
    uId?: string
  ) {
    for (const s of schedules) {
      if (s?.contributor?.uId === uId) {
        return s?.schedules?.find((s) => s?.schedule?.enabled)?.schedule?.uId;
      }
    }
    return schedules
      ?.find((_) => true)
      ?.schedules?.find((s) => s?.schedule?.enabled)?.schedule?.uId;
  }

  updateQueryParams(params: Params) {
    this.$queryParams.set({ ...this.$queryParams(), ...params });
  }
}
