<div
  class="bg-base text-base-content w-[24rem] max-h-[40rem] p-4 grid items-start"
  *ngIf="userBreakItems$ | async; let userBreakItems"
>
  @if (userBreakItems?.length > 0) {
  <button
    type="button"
    class="secondary small flex flex-row items-center justify-center"
    (click)="onActionClick({ type: addAction.type })"
  >
    <span class="material-symbols-rounded hrp-text-body-small">{{
      addAction?.displayIcon
    }}</span>
    {{ addAction?.label }}
  </button>

  <div class="flex flex-col items-center justify-center gap-2 my-4">
    @for (breakItem of userBreakItems; track breakItem; let i = $index) {
    <div
      class="w-full flex flex-row items-center justify-between border-hrp-subtle-600 border border-solid rounded-[5px]"
      [class.item-edit-mode]="getDisplayForm() === i"
    >
      <div class="flex flex-row items-center p-2 hrp-text-body-small-bold">
        <span
          >{{ getTimeShortString(breakItem?.start) }} -
          {{ getTimeShortString(breakItem?.stop) }}</span
        >
        <divider-circle class="mx-2"></divider-circle>
        <span>Pause</span>
      </div>
      <div class="flex flex-row items-center p-2">
        <button
          type="button"
          class="tertiary small flex flex-row items-center justify-center mr-1"
          (click)="
            onActionClick({
              type: removeAction?.type,
              scheduleItemInfo: breakItem
            })
          "
        >
          <span class="material-symbols-rounded">{{
            removeAction?.displayIcon
          }}</span>
        </button>

        @if (!(getDisplayForm() === i)) {
        <button
          type="button"
          class="primary small"
          (click)="
            onActionClick({
              type: editAction?.type,
              scheduleItemInfo: breakItem,
              index: i
            })
          "
        >
          <span class="material-symbols-rounded">{{
            editAction?.displayIcon
          }}</span>
        </button>
        } @else {
        <button
          type="button"
          class="tertiary small"
          (click)="clearAndCloseForm()"
        >
          <span class="material-symbols-rounded">close</span>
        </button>
        }
      </div>
    </div>
    }
  </div>

  } @if (userBreakItems?.length === 0 || getDisplayForm() !== null) {
  <form
    (ngSubmit)="onSubmit(userBreakItems)"
    class="flex flex-col max-h-[20rem]"
    [formGroup]="form"
  >
    <div class="grid grid-flow-col gap-3">
      <par-form-field>
        <label for="breakTimeStart" class="label"> Pause von* </label>
        <input
          type="time"
          name="breakTimeStart"
          id="breakTimeStart"
          class="par-control par-input-w-[100%] par-input-ptb-[0px] par-input-h-[3rem] par-input-plr-2 hrp-text-body-bold disabled:text-black"
          formControlName="breakTimeStart"
          [ngClass]="{
            'dialog-error':
              (breakTimeStart?.invalid || checkFormErrors()) &&
              breakTimeStart?.dirty
          }"
          (change)="form.updateValueAndValidity()"
        />
      </par-form-field>

      <par-form-field>
        <label for="breakTimeStop" class="label"> bis* </label>
        <input
          type="time"
          name="breakTimeStop"
          id="breakTimeStop"
          class="par-control par-input-w-[100%] par-input-ptb-[0px] par-input-h-[3rem] par-input-plr-2 hrp-text-body-bold disabled:text-black"
          formControlName="breakTimeStop"
          [ngClass]="{
            'dialog-error':
              (breakTimeStop?.invalid || checkFormErrors()) &&
              breakTimeStop?.dirty
          }"
          (change)="form.updateValueAndValidity()"
        />
      </par-form-field>
    </div>

    <dialog-form-errors
      *ngIf="formErrors$ | async; let formErrors"
      [formErrors]="formErrors"
    ></dialog-form-errors>
    <dialog-form-notification
      [overlappingCount]="allOverlappingBreakItemsWithoutCurrentUser$ | async"
    ></dialog-form-notification>

    <div class="grid pt-2">
      <button
        (click)="form.updateValueAndValidity()"
        type="submit"
        class="primary"
      >
        <span>{{ transloco.translate("accept") }}</span>
      </button>
    </div>
  </form>
  }
</div>
